export const censusData = {
    "32001": {"county":"Churchill","county_ascii":"Churchill","name":"Churchill County","id":"32001","state_id":"NV","state_name":"Nevada","lat":"39.5809","lng":"-118.3358","population":"25409","main":""},
    "32003":{"county":"Clark","county_ascii":"Clark","name":"Clark County","id":"32003","state_id":"NV","state_name":"Nevada","lat":"36.2152","lng":"-115.0135","population":"2265926","main":"1"},
    "32005":{"county":"Douglas","county_ascii":"Douglas","name":"Douglas County","id":"32005","state_id":"NV","state_name":"Nevada","lat":"38.9123","lng":"-119.6162","population":"49476","main":""},
    "32007":{"county":"Elko","county_ascii":"Elko","name":"Elko County","id":"32007","state_id":"NV","state_name":"Nevada","lat":"41.1458","lng":"-115.3577","population":"53600","main":""},
    "32009":{"county":"Esmeralda","county_ascii":"Esmeralda","name":"Esmeralda County","id":"32009","state_id":"NV","state_name":"Nevada","lat":"37.7846","lng":"-117.6323","population":"980","main":""},
    "32011":{"county":"Eureka","county_ascii":"Eureka","name":"Eureka County","id":"32011","state_id":"NV","state_name":"Nevada","lat":"39.9839","lng":"-116.2686","population":"1622","main":""},
    "32013":{"county":"Humboldt","county_ascii":"Humboldt","name":"Humboldt County","id":"32013","state_id":"NV","state_name":"Nevada","lat":"41.4068","lng":"-118.1120","population":"17266","main":""},
    "32015":{"county":"Lander","county_ascii":"Lander","name":"Lander County","id":"32015","state_id":"NV","state_name":"Nevada","lat":"39.9337","lng":"-117.0380","population":"5728","main":""},
    "32017":{"county":"Lincoln","county_ascii":"Lincoln","name":"Lincoln County","id":"32017","state_id":"NV","state_name":"Nevada","lat":"37.6433","lng":"-114.8775","population":"4507","main":""},
    "32019":{"county":"Lyon","county_ascii":"Lyon","name":"Lyon County","id":"32019","state_id":"NV","state_name":"Nevada","lat":"39.0206","lng":"-119.1890","population":"59435","main":""},
    "32021":{"county":"Mineral","county_ascii":"Mineral","name":"Mineral County","id":"32021","state_id":"NV","state_name":"Nevada","lat":"38.5386","lng":"-118.4349","population":"4568","main":""},
    "32023":{"county":"Nye","county_ascii":"Nye","name":"Nye County","id":"32023","state_id":"NV","state_name":"Nevada","lat":"38.0422","lng":"-116.4719","population":"51698","main":"1"},
    "32027":{"county":"Pershing","county_ascii":"Pershing","name":"Pershing County","id":"32027","state_id":"NV","state_name":"Nevada","lat":"40.4404","lng":"-118.4044","population":"6587","main":""},
    "32029":{"county":"Storey","county_ascii":"Storey","name":"Storey County","id":"32029","state_id":"NV","state_name":"Nevada","lat":"39.4470","lng":"-119.5293","population":"4095","main":""},
    "32031":{"county":"Washoe","county_ascii":"Washoe","name":"Washoe County","id":"32031","state_id":"NV","state_name":"Nevada","lat":"40.6655","lng":"-119.6643","population":"486674","main":""},
    "32033":{"county":"White Pine","county_ascii":"White Pine","name":"White Pine County","id":"32033","state_id":"NV","state_name":"Nevada","lat":"39.4421","lng":"-114.9017","population":"8997","main":""},
    "32510":{"county":"Carson City","county_ascii":"Carson City","name":"Carson City","id":"32510","state_id":"NV","state_name":"Nevada","lat":"39.1511","lng":"-119.7476","population":"58249","main":""},
    "06037":{"county":"Los Angeles","county_ascii":"Los Angeles","name":"Los Angeles County","id":"06037","state_id":"CA","state_name":"California","lat":"34.3219","lng":"-118.2247","population":"9936690","main":"1"},
    "06073":{"county":"San Diego","county_ascii":"San Diego","name":"San Diego County","id":"06073","state_id":"CA","state_name":"California","lat":"33.0343","lng":"-116.7351","population":"3289701","main":"1"},
    "06059":{"county":"Orange","county_ascii":"Orange","name":"Orange County","id":"06059","state_id":"CA","state_name":"California","lat":"33.7031","lng":"-117.7609","population":"3175227","main":"1"},
    "06065":{"county":"Riverside","county_ascii":"Riverside","name":"Riverside County","id":"06065","state_id":"CA","state_name":"California","lat":"33.7437","lng":"-115.9938","population":"2429487","main":"1"},
    "06071":{"county":"San Bernardino","county_ascii":"San Bernardino","name":"San Bernardino County","id":"06071","state_id":"CA","state_name":"California","lat":"34.8414","lng":"-116.1785","population":"2180563","main":"1"},
    "06085":{"county":"Santa Clara","county_ascii":"Santa Clara","name":"Santa Clara County","id":"06085","state_id":"CA","state_name":"California","lat":"37.2318","lng":"-121.6951","population":"1916831","main":""},
    "06001":{"county":"Alameda","county_ascii":"Alameda","name":"Alameda County","id":"06001","state_id":"CA","state_name":"California","lat":"37.6469","lng":"-121.8887","population":"1663823","main":""},
    "06067":{"county":"Sacramento","county_ascii":"Sacramento","name":"Sacramento County","id":"06067","state_id":"CA","state_name":"California","lat":"38.4493","lng":"-121.3443","population":"1579211","main":""},
    "06013":{"county":"Contra Costa","county_ascii":"Contra Costa","name":"Contra Costa County","id":"06013","state_id":"CA","state_name":"California","lat":"37.9192","lng":"-121.9275","population":"1162648","main":""},
    "06019":{"county":"Fresno","county_ascii":"Fresno","name":"Fresno County","id":"06019","state_id":"CA","state_name":"California","lat":"36.7582","lng":"-119.6493","population":"1008280","main":""},
    "06029":{"county":"Kern","county_ascii":"Kern","name":"Kern County","id":"06029","state_id":"CA","state_name":"California","lat":"35.3426","lng":"-118.7301","population":"906883","main":""},
    "06075":{"county":"San Francisco","county_ascii":"San Francisco","name":"San Francisco County","id":"06075","state_id":"CA","state_name":"California","lat":"37.7556","lng":"-122.4450","population":"851036","main":""},
    "06111":{"county":"Ventura","county_ascii":"Ventura","name":"Ventura County","id":"06111","state_id":"CA","state_name":"California","lat":"34.4561","lng":"-119.0836","population":"842009","main":"1"},
    "06077":{"county":"San Joaquin","county_ascii":"San Joaquin","name":"San Joaquin County","id":"06077","state_id":"CA","state_name":"California","lat":"37.9348","lng":"-121.2714","population":"779445","main":""},
    "06081":{"county":"San Mateo","county_ascii":"San Mateo","name":"San Mateo County","id":"06081","state_id":"CA","state_name":"California","lat":"37.4228","lng":"-122.3291","population":"754250","main":""},
    "06099":{"county":"Stanislaus","county_ascii":"Stanislaus","name":"Stanislaus County","id":"06099","state_id":"CA","state_name":"California","lat":"37.5591","lng":"-120.9977","population":"552063","main":""},
    "06097":{"county":"Sonoma","county_ascii":"Sonoma","name":"Sonoma County","id":"06097","state_id":"CA","state_name":"California","lat":"38.5290","lng":"-122.8882","population":"488436","main":""},
    "06107":{"county":"Tulare","county_ascii":"Tulare","name":"Tulare County","id":"06107","state_id":"CA","state_name":"California","lat":"36.2202","lng":"-118.8005","population":"473446","main":""},
    "06095":{"county":"Solano","county_ascii":"Solano","name":"Solano County","id":"06095","state_id":"CA","state_name":"California","lat":"38.2700","lng":"-121.9328","population":"450995","main":""},
    "06083":{"county":"Santa Barbara","county_ascii":"Santa Barbara","name":"Santa Barbara County","id":"06083","state_id":"CA","state_name":"California","lat":"34.6729","lng":"-120.0169","population":"445213","main":"1"},
    "06053":{"county":"Monterey","county_ascii":"Monterey","name":"Monterey County","id":"06053","state_id":"CA","state_name":"California","lat":"36.2171","lng":"-121.2388","population":"437609","main":""},
    "06061":{"county":"Placer","county_ascii":"Placer","name":"Placer County","id":"06061","state_id":"CA","state_name":"California","lat":"39.0634","lng":"-120.7177","population":"406608","main":""},
    "06047":{"county":"Merced","county_ascii":"Merced","name":"Merced County","id":"06047","state_id":"CA","state_name":"California","lat":"37.1919","lng":"-120.7177","population":"282290","main":""},
    "06079":{"county":"San Luis Obispo","county_ascii":"San Luis Obispo","name":"San Luis Obispo County","id":"06079","state_id":"CA","state_name":"California","lat":"35.3871","lng":"-120.4044","population":"281712","main":""},
    "06087":{"county":"Santa Cruz","county_ascii":"Santa Cruz","name":"Santa Cruz County","id":"06087","state_id":"CA","state_name":"California","lat":"37.0562","lng":"-122.0018","population":"268571","main":""},
    "06041":{"county":"Marin","county_ascii":"Marin","name":"Marin County","id":"06041","state_id":"CA","state_name":"California","lat":"38.0734","lng":"-122.7234","population":"260485","main":""},
    "06113":{"county":"Yolo","county_ascii":"Yolo","name":"Yolo County","id":"06113","state_id":"CA","state_name":"California","lat":"38.6866","lng":"-121.9011","population":"217141","main":""},
    "06007":{"county":"Butte","county_ascii":"Butte","name":"Butte County","id":"06007","state_id":"CA","state_name":"California","lat":"39.6669","lng":"-121.6007","population":"213605","main":""},
    "06017":{"county":"El Dorado","county_ascii":"El Dorado","name":"El Dorado County","id":"06017","state_id":"CA","state_name":"California","lat":"38.7787","lng":"-120.5247","population":"191713","main":""},
    "06089":{"county":"Shasta","county_ascii":"Shasta","name":"Shasta County","id":"06089","state_id":"CA","state_name":"California","lat":"40.7637","lng":"-122.0405","population":"181852","main":""},
    "06025":{"county":"Imperial","county_ascii":"Imperial","name":"Imperial County","id":"06025","state_id":"CA","state_name":"California","lat":"33.0395","lng":"-115.3654","population":"179578","main":"1"},
    "06039":{"county":"Madera","county_ascii":"Madera","name":"Madera County","id":"06039","state_id":"CA","state_name":"California","lat":"37.2180","lng":"-119.7627","population":"157243","main":""},
    "06031":{"county":"Kings","county_ascii":"Kings","name":"Kings County","id":"06031","state_id":"CA","state_name":"California","lat":"36.0752","lng":"-119.8155","population":"152515","main":""},
    "06055":{"county":"Napa","county_ascii":"Napa","name":"Napa County","id":"06055","state_id":"CA","state_name":"California","lat":"38.5070","lng":"-122.3306","population":"137384","main":""},
    "06023":{"county":"Humboldt","county_ascii":"Humboldt","name":"Humboldt County","id":"06023","state_id":"CA","state_name":"California","lat":"40.6993","lng":"-123.8756","population":"136132","main":""},
    "06057":{"county":"Nevada","county_ascii":"Nevada","name":"Nevada County","id":"06057","state_id":"CA","state_name":"California","lat":"39.3013","lng":"-120.7689","population":"102322","main":""},
    "06101":{"county":"Sutter","county_ascii":"Sutter","name":"Sutter County","id":"06101","state_id":"CA","state_name":"California","lat":"39.0346","lng":"-121.6948","population":"99101","main":""},
    "06045":{"county":"Mendocino","county_ascii":"Mendocino","name":"Mendocino County","id":"06045","state_id":"CA","state_name":"California","lat":"39.4402","lng":"-123.3915","population":"91145","main":""},
    "06115":{"county":"Yuba","county_ascii":"Yuba","name":"Yuba County","id":"06115","state_id":"CA","state_name":"California","lat":"39.2690","lng":"-121.3513","population":"81705","main":""},
    "06033":{"county":"Lake","county_ascii":"Lake","name":"Lake County","id":"06033","state_id":"CA","state_name":"California","lat":"39.1000","lng":"-122.7534","population":"68024","main":""},
    "06103":{"county":"Tehama","county_ascii":"Tehama","name":"Tehama County","id":"06103","state_id":"CA","state_name":"California","lat":"40.1256","lng":"-122.2341","population":"65484","main":""},
    "06069":{"county":"San Benito","county_ascii":"San Benito","name":"San Benito County","id":"06069","state_id":"CA","state_name":"California","lat":"36.6057","lng":"-121.0750","population":"64753","main":""},
    "06109":{"county":"Tuolumne","county_ascii":"Tuolumne","name":"Tuolumne County","id":"06109","state_id":"CA","state_name":"California","lat":"38.0276","lng":"-119.9548","population":"54993","main":""},
    "06009":{"county":"Calaveras","county_ascii":"Calaveras","name":"Calaveras County","id":"06009","state_id":"CA","state_name":"California","lat":"38.2046","lng":"-120.5541","population":"45674","main":""},
    "06093":{"county":"Siskiyou","county_ascii":"Siskiyou","name":"Siskiyou County","id":"06093","state_id":"CA","state_name":"California","lat":"41.5927","lng":"-122.5404","population":"44049","main":""},
    "06005":{"county":"Amador","county_ascii":"Amador","name":"Amador County","id":"06005","state_id":"CA","state_name":"California","lat":"38.4464","lng":"-120.6511","population":"40577","main":""},
    "06035":{"county":"Lassen","county_ascii":"Lassen","name":"Lassen County","id":"06035","state_id":"CA","state_name":"California","lat":"40.6736","lng":"-120.5943","population":"31873","main":""},
    "06021":{"county":"Glenn","county_ascii":"Glenn","name":"Glenn County","id":"06021","state_id":"CA","state_name":"California","lat":"39.5981","lng":"-122.3919","population":"28657","main":""},
    "06015":{"county":"Del Norte","county_ascii":"Del Norte","name":"Del Norte County","id":"06015","state_id":"CA","state_name":"California","lat":"41.7431","lng":"-123.8972","population":"27462","main":""},
    "06011":{"county":"Colusa","county_ascii":"Colusa","name":"Colusa County","id":"06011","state_id":"CA","state_name":"California","lat":"39.1775","lng":"-122.2370","population":"21811","main":""},
    "06063":{"county":"Plumas","county_ascii":"Plumas","name":"Plumas County","id":"06063","state_id":"CA","state_name":"California","lat":"40.0046","lng":"-120.8385","population":"19650","main":""},
    "06027":{"county":"Inyo","county_ascii":"Inyo","name":"Inyo County","id":"06027","state_id":"CA","state_name":"California","lat":"36.5111","lng":"-117.4107","population":"18829","main":""},
    "06043":{"county":"Mariposa","county_ascii":"Mariposa","name":"Mariposa County","id":"06043","state_id":"CA","state_name":"California","lat":"37.5815","lng":"-119.9054","population":"17130","main":""},
    "06105":{"county":"Trinity","county_ascii":"Trinity","name":"Trinity County","id":"06105","state_id":"CA","state_name":"California","lat":"40.6507","lng":"-123.1126","population":"15889","main":""},
    "06051":{"county":"Mono","county_ascii":"Mono","name":"Mono County","id":"06051","state_id":"CA","state_name":"California","lat":"37.9391","lng":"-118.8868","population":"13219","main":""},
    "06049":{"county":"Modoc","county_ascii":"Modoc","name":"Modoc County","id":"06049","state_id":"CA","state_name":"California","lat":"41.5898","lng":"-120.7250","population":"8651","main":""},
    "06091":{"county":"Sierra","county_ascii":"Sierra","name":"Sierra County","id":"06091","state_id":"CA","state_name":"California","lat":"39.5803","lng":"-120.5161","population":"2916","main":""},
    "06003":{"county":"Alpine","county_ascii":"Alpine","name":"Alpine County","id":"06003","state_id":"CA","state_name":"California","lat":"38.5972","lng":"-119.8207","population":"1515","main":""}
};